body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #1b1b1b;
  text-align: center;
}

div {
  font-size: 30pt;
  font-family: "Fontin Regular";
}

.item-box {
  display: grid;
  size: absolute;
  height: 100%;
  width: 100%;
  grid-template-rows: 1fr;
  grid-auto-columns: 100%;
}

.item-mod-text {
  margin-left: 3%;
  margin-right: 3%;
  font-size: 13pt;
  font-family: "Fontin SmallCaps";
  color: #cecece;
  padding-top: 5px;
  padding-bottom: 5px;
}

.base-item-art-grid {
  display: grid;
  grid-auto-rows: 1fr;
  grid-template-columns: 1fr 175px 1fr;
  position: absolute;
  bottom: 137px;
  justify-items: center;
  width: 100%;
}

.item-header {
  padding: 8px 5px;
  margin-bottom: 10px;
  display: block;
  overflow: hidden;
  position: relative;
  white-space: nowrap;
  background-repeat: repeat-x;
  background-size: 100% 100%;
}

.item-header-normal-text {
  color: #e4e4e4;
  font-family: "Fontin SmallCaps";
  font-size: 16pt;
}

.item-header-magic-text {
  color: #0659d6;
  font-family: "Fontin SmallCaps";
  font-size: 14pt;
  white-space: normal;
  margin-left: 13%;
  margin-right: 13%;
}

.item-header-rare-text {
  color: #e0d759;
  font-family: "Fontin SmallCaps";
  font-size: 15pt;
  margin-left: 13%;
  margin-right: 13%;
}

.item-header-unique-text {
  color: #d86d26;
  font-family: "Fontin SmallCaps";
  font-size: 16pt;
}

.item-header-normal {
  background-image: url("./resources/img/header/normal_mid.png");
}

.item-header-normal::before {
  background-image: url("./resources/img/header/normal_left.png");
  content: "";
  background-repeat: no-repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-position: 0 50%;
  background-size: contain;
}

.item-header-normal::after {
  background-image: url("./resources/img/header/normal_right.png");
  content: "";
  background-repeat: no-repeat;
  display: block;
  position: absolute;
  height: 100%;
  width: 100%;
  right: 0;
  top: 0;
  background-position: 100% 50%;
  background-size: contain;
}

.item-header-magic {
  background-image: url("./resources/img/header/magic_mid.png");
}

.item-header-magic::before {
  background-image: url("./resources/img/header/magic_left.png");
  content: "";
  background-repeat: no-repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-position: 0 50%;
  background-size: contain;
}

.item-header-magic::after {
  background-image: url("./resources/img/header/magic_right.png");
  content: "";
  background-repeat: no-repeat;
  display: block;
  position: absolute;
  height: 100%;
  width: 100%;
  right: 0;
  top: 0;
  background-position: 100% 50%;
  background-size: contain;
}

.item-header-rare {
  background-image: url("./resources/img/header/rare_mid.png");
}

.item-header-rare::before {
  background-image: url("./resources/img/header/rare_left.png");
  content: "";
  background-repeat: no-repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-position: 0 50%;
  background-size: contain;
}

.item-header-rare::after {
  background-image: url("./resources/img/header/rare_right.png");
  content: "";
  background-repeat: no-repeat;
  display: block;
  position: absolute;
  height: 100%;
  width: 100%;
  right: 0;
  top: 0;
  background-position: 100% 50%;
  background-size: contain;
}

.item-header-unique {
  background-image: url("./resources/img/header/unique_mid.png")
}

.item-header-unique::before {
  background-image: url("./resources/img/header/unique_left.png");
  content: "";
  background-repeat: no-repeat;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-position: 0 50%;
  background-size: contain;
}

.item-header-unique::after {
  background-image: url("./resources/img/header/unique_right.png");
  content: "";
  background-repeat: no-repeat;
  display: block;
  position: absolute;
  height: 100%;
  width: 100%;
  right: 0;
  top: 0;
  background-position: 100% 50%;
  background-size: contain;
}

.currency-grid {
  position: fixed;
  bottom: 4px;
  min-width: 100%;
  padding-top: 4px;
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(6, 62px);
  grid-auto-rows: 62px;
  column-gap: 4px;
  row-gap: 4px;
}

.currency-cell {
  padding: 2px;
  text-align: center;
  background-color: #242424;
}

.currency-cell>img {
  width: 100%;
  height: 100%;
}

@font-face {
  font-family: "Fontin Bold";
  src: url("./resources/fonts/Fontin-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Fontin Italic";
  src: url("./resources/fonts/Fontin-Italic.ttf") format("truetype");
}

@font-face {
  font-family: "Fontin Regular";
  src: url("./resources/fonts/Fontin-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Fontin SmallCaps";
  src: url("./resources/fonts/Fontin-SmallCaps.ttf") format("truetype");
}
